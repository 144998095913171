import * as React from 'react'
import { Link, graphql } from 'gatsby'

import styled from '@emotion/styled'
import { Form, Field, FieldProps } from 'react-final-form'

import { FieldValidator } from 'final-form'
import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import CartContext, { ICartItem } from '../components/shop/CartContext'
import ShopLayout from '../layouts/Shop'
import formatCurrency from '../utils/formatCurrency'

const StyledLabel = styled.label`
  display: block;
  margin-top: 10px;
`

const StyledValidationError = styled.span`
  color: red;
`

const StyledPfandInput = styled.input`
  width: 50px;
`

const required = value => (value ? undefined : 'Pflichtfeld')

interface IInputFieldProps {
  validate: FieldValidator<any>
  name: string
  label: string
}
function InputField(props: IInputFieldProps) {
  return (
    <Field name={props.name} validate={props.validate}>
      {({ input, meta }) => (
        <div>
          <StyledLabel>{props.label}</StyledLabel>
          <input {...input} type="text" />
          {meta.error && meta.touched && <StyledValidationError> {meta.error}</StyledValidationError>}
        </div>
      )}
    </Field>
  )
}

const CheckoutForm = (props: any) => {
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [error, setError] = React.useState<string | undefined>(undefined)
  const cartContext = React.useContext(CartContext)
  const products = props.data.allProductsJson.edges.map((p: any) => p.node)

  let sum = 0
  let isPriceExact = true
  let hasPfand = false
  {
    cartContext.cart.map(cartItem => {
      const product = products.find((p: any) => p.slug === cartItem.slug)
      if (!product) return null
      sum += cartItem.amount * (product.price + (product.pfand ? 0.3 : 0))
      if (!product.priceExact) isPriceExact = false
      if (product.pfand) hasPfand = true
    })
  }
  const showShippingCosts = sum < 70

  if (!showSuccess && !cartContext.cart.length) return <div>Keine Produkte im Warenkorb.</div>

  return (
    <div>
      <Form
        onSubmit={async values => {
          const res = await fetch('/api/order.php', {
            method: 'POST',
            body: JSON.stringify({
              cart: cartContext.cart,
              order: values
            })
          })
          if (res.status !== 200) {
            setError(await res.text())
          } else {
            setShowSuccess(true)
            cartContext.clearCart()
          }
        }}
        validate={values => {
          const errors: any = {}
          if (!values.delivery) {
            errors.delivery = 'Pflichtfeld'
          }
          if (!values.payment) {
            errors.payment = 'Pflichtfeld'
          }
          return errors
        }}
        render={({ handleSubmit }) => {
          if (showSuccess) {
            return (
              <Field name="delivery">
                {({ input: deliveryInput }) => (
                  <Field name="payment">
                    {({ input: paymentInput }) => {
                      if (paymentInput.value === 'Sofort') {
                        return (
                          <div>
                            Vielen Dank für die Bestellung, Sie erhalten einen Link zur Bezahlung per Sofortüberweisung sobald wir die
                            Bestellung hergerichtet haben.
                          </div>
                        )
                      }
                      if (deliveryInput.value === 'Zustellung') {
                        return <div>Vielen Dank für die Bestellung, wir melden uns bei Ihnen.</div>
                      }
                      return <div>Vielen Dank für die Bestellung, wir bereiten sie zur Abholung vor.</div>
                    }}
                  </Field>
                )}
              </Field>
            )
          }
          return (
            <form onSubmit={handleSubmit}>
              <InputField label="Vorname" name="firstName" validate={required} />
              <InputField label="Nachname" name="lastName" validate={required} />
              <InputField label="Straße" name="street" validate={required} />
              <InputField label="PLZ" name="zip" validate={required} />
              <InputField label="Ort" name="city" validate={required} />
              <InputField label="E-Mail" name="email" validate={required} />
              <InputField label="Telefon" name="phone" validate={required} />
              <div>
                <StyledLabel>
                  Abholung
                  <Field name="delivery">
                    {({ meta }) => <>{meta.error && meta.touched && <StyledValidationError> {meta.error}</StyledValidationError>}</>}
                  </Field>
                </StyledLabel>
                <label>
                  <Field name="delivery" component="input" type="radio" value="Abholung Hofladen" /> Abholung im Hofladen
                </label>
                <br />
                <Field name="delivery">
                  {({ input: deliveryInput }) => (
                    <>
                      {deliveryInput.value === 'Abholung Hofladen' && (
                        <Field name="pickupTime" validate={required}>
                          {({ input, meta }) => (
                            <div>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<label>Zeitpunkt: </label>
                              <input {...input} type="text" />
                              {meta.error && meta.touched && <StyledValidationError> {meta.error}</StyledValidationError>}
                            </div>
                          )}
                        </Field>
                      )}
                    </>
                  )}
                </Field>
                <label>
                  <Field name="delivery" component="input" type="radio" value="Abholung Schranne" /> Abholung am Markt in Salzburg -
                  Schranne (Do 06:00-13:00)
                </label>
                <br />
                <label>
                  <Field name="delivery" component="input" type="radio" value="Abholung Kajetanerplatz" /> Abholung am Markt in Salzburg -
                  Kajetanerplatz (Fr 08:00-13:00)
                </label>
                <br />
                <label>
                  <Field name="delivery" component="input" type="radio" value="Abholung Grünmarkt" /> Abholung am Markt in Salzburg -
                  Grünmarkt (Sa 08:00-12:30)
                </label>
                <br />
                <label>
                  <Field name="delivery" component="input" type="radio" value="Abholung Hallein" /> Abholung am Markt in Hallein -
                  Kornsteinplatz (Fr 14:00-17:00)
                </label>
                <br />
                <label>
                  <Field name="delivery" component="input" type="radio" value="Abholung Henndorf" /> Abholung am Markt in Henndorf (Do 15:30
                  - 18:00)
                </label>
                {/*
                <br />
                <label>
                  <Field name="delivery" component="input" type="radio" value="Zustellung" /> Zustellung{' '}
                  {showShippingCosts && <>(Unkostenbeitrag 5 €)</>}
                </label>
                */}
              </div>

              <div>
                <StyledLabel>
                  Bezahlung
                  <Field name="payment">
                    {({ meta }) => <>{meta.error && meta.touched && <StyledValidationError> {meta.error}</StyledValidationError>}</>}
                  </Field>
                </StyledLabel>
                <label>
                  <Field name="payment" component="input" type="radio" value="Bar" />{' '}
                  <Field name="delivery">
                    {({ input }) => <>Bar bei {input.value === 'Zustellung' ? <>Lieferung</> : <>Abholung</>}</>}
                  </Field>
                </label>
                {/*
                <br />
                <label>
                  <Field name="payment" component="input" type="radio" value="Sofort" /> Sofortüberweisung <strong>(NEU)</strong>
                </label>
                */}
              </div>

              <Field name="pfandRetoure" initialValue="0">
                {({ input, meta }) => (
                  <div>
                    <StyledLabel>Pfandgläser retoure</StyledLabel>
                    <StyledPfandInput {...input} type="number" /> Stk
                    {meta.error && meta.touched && <StyledValidationError> {meta.error}</StyledValidationError>}
                  </div>
                )}
              </Field>
              <div>
                <StyledLabel>Kommentar</StyledLabel>
                <Field name="comment" component="textarea" />
              </div>
              {error && <p>{error}</p>}
              <button type="submit">Kaufen</button>
              <p>
                Summe: {!isPriceExact && <>ca. </>}
                {formatCurrency(sum)}
                {showShippingCosts && (
                  <Field name="delivery">
                    {({ input: deliveryInput }) => <>{deliveryInput.value === 'Zustellung' && <> + 5,00 € Zustellung</>}</>}
                  </Field>
                )}
                <Field name="pfandRetoure">
                  {({ input: pfandRetoureInput }) => (
                    <>{pfandRetoureInput.value > 0 && <> {formatCurrency(pfandRetoureInput.value * -0.3)} Pfand Retoure</>}</>
                  )}
                </Field>
              </p>
              {hasPfand && (
                <p>
                  Pfandgläser/flaschen: Wir verpacken unsere Produkte in umweltfreundliche Mehrweggläser und verrechnen dafür 0,30 € Pfand /
                  Stk. Sie können die Pfandgläser auf den Märkten Retoure bringen.
                </p>
              )}
            </form>
          )
        }}
      />
    </div>
  )
}

const Checkout = (props: any) => {
  return (
    <ShopLayout>
      <Page>
        <Container>
          <h1>Kasse</h1>
          <CheckoutForm {...props} />
        </Container>
      </Page>
    </ShopLayout>
  )
}

export default Checkout

export const query = graphql`
  query ShopCheckout {
    allProductsJson {
      edges {
        node {
          name
          slug
          price
          priceExact
          pfand
        }
      }
    }
  }
`
